.graphin-core {
  height: 100%;
  width: 100%;
  min-height: 500px;
  background: #fff;
}
@font-face {
  font-family: 'graphin';
  /* project id 1522921 */
  src: url('//at.alicdn.com/t/font_1522921_m3irqw8ynx.eot');
  src: url('//at.alicdn.com/t/font_1522921_m3irqw8ynx.eot?#iefix') format('embedded-opentype'), url('//at.alicdn.com/t/font_1522921_m3irqw8ynx.woff2') format('woff2'), url('//at.alicdn.com/t/font_1522921_m3irqw8ynx.woff') format('woff'), url('//at.alicdn.com/t/font_1522921_m3irqw8ynx.ttf') format('truetype'), url('//at.alicdn.com/t/font_1522921_m3irqw8ynx.svg#graphin') format('svg');
}
